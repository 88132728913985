import axios from "axios";
import { BASE_URL } from "../config/config";

export const getAllPosts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/api/posts`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getPost = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/posts`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const addPost = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/postData`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
