import React from "react";
import { CONSTANTS } from "../constants/constants";

export default function PostItem({ item, onItemClick }) {
  return (
    <div
      className="PostItem"
      data-testid="postItem"
      onClick={() => onItemClick(item)}
    >
      <div>
        <span>
          <label>{CONSTANTS.POST_DETAILS.ID}</label>
          <span>{item.id}</span>
        </span>
        <span>
          <label>{CONSTANTS.POST_DETAILS.TITLE}</label>
          <span>{item.title}</span>
        </span>
      </div>
    </div>
  );
}
