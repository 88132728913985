import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getAllPosts } from "../apis/apis";
import { BASE_URL } from "../config/config";
import { CONSTANTS } from "../constants/constants";
import AddPost from "./AddPost";
import DisplaySelectedPost from "./DisplaySelectedPost";
import ErrorComponent from "./ErrorComponent";
import Loader from "./Loader";
import PaginationComponent from "./PaginationComponent";
import PostItem from "./PostItem";

export default function Dashboard() {
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState({});
  const [selectedPost, setSelectedPost] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [showAddPostModal, setshowAddPostModal] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dataLimit = 5;
  const pageLimit = 3;

  useEffect(() => {
    setPostData();
  }, []);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return posts.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    let totalPages = Math.ceil(posts.length / dataLimit);
    let groupSize =
      start + pageLimit <= totalPages
        ? pageLimit
        : totalPages - pageLimit > 0
        ? totalPages - pageLimit
        : totalPages;

    return new Array(groupSize || 0).fill().map((_, idx) => start + idx + 1);
  };

  const setPostData = async () => {
    setisLoading(true);
    try {
      const data = await getAllPosts();
      console.log(">> ", data);
      if (data == 401) {
        setError({ msg: "Unauthorized Access" });
      } else if (data == 404) {
        setError({ msg: "Could not found the requested data" });
      } else if (data == "Error: Network Error") {
        setError({
          msg: "Network error please check the server or refresh the page",
        });
      } else {
        setPosts(data);
        setPages(Math.ceil(data.length / dataLimit));
      }
    } catch (error) {
      setError(error);
    }
    setisLoading(false);
  };

  const onItemClick = (data) => {
    const selectedItem = posts.find((item) => item.id === data.id);
    setSelectedPost(selectedItem);
    setshowModal(true);
  };

  const handleModalClose = () => {
    setshowModal(false);
    setSelectedPost({});
  };
  const handleInputModalClose = () => {
    setshowAddPostModal(false);
  };
  const addPostListener = () => {
    setshowAddPostModal(true);
  };

  const onSavePostData = async () => {
    await setPost({
      ...post,
      id: Math.random() * 1000,
      published_at: new Date().toISOString(),
    });
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    await axios.post(`${BASE_URL}/api/postData`, post).then((res) => {
      if (res.data === 200) {
        console.log("Data post success");
      } else {
        console.log("Data post failed ");
      }
    });
    setshowAddPostModal(false);
  };

  const onInputChange = (e) => {
    if (e.target.id === "title") {
      post.title = e.target.value;
    } else if (e.target.id === "description") {
      post.text = e.target.value;
    }
    setPost({ ...post });
  };

  return (
    <>
      <div className="Dashboard">
        <div className="title">{CONSTANTS.TITLE}</div>
        <div className="DisplayList">
          {error ? (
            <ErrorComponent message={error.msg} />
          ) : Array.isArray(posts) && posts.length > 0 ? (
            <>
              <div className="header">
                <div className="subTitle">{CONSTANTS.SUB_TITLE}</div>
                <div className="addPost">
                  <Button onClick={addPostListener}>Add Post</Button>
                </div>
              </div>
              {getPaginatedData().map((item, index) => (
                <PostItem item={item} key={index} onItemClick={onItemClick} />
              ))}
              <PaginationComponent
                goToPreviousPage={goToPreviousPage}
                currentPage={currentPage}
                getPaginationGroup={getPaginationGroup}
                changePage={changePage}
                pages={pages}
                goToNextPage={goToNextPage}
              />
            </>
          ) : isLoading ? (
            <Loader />
          ) : (
            <h3>{CONSTANTS.NO_DATA}</h3>
          )}
        </div>
      </div>
      <DisplaySelectedPost
        handleModalClose={() => handleModalClose()}
        showModal={showModal}
        item={selectedPost}
      />
      <AddPost
        handleModalClose={() => handleInputModalClose()}
        showModal={showAddPostModal}
        onSavePostData={onSavePostData}
        onInputChange={onInputChange}
      />
    </>
  );
}
