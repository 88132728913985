export const CONSTANTS = {
  ERROR_MESSAGE: "Oops, there is an error : ",
  NO_DATA: "No result found",
  TITLE: "Twitter App",
  SUB_TITLE: "All Posts",
  POST_DETAILS: {
    POST_ID: "Post ID: ",
    DESCRIPTION: "Description: ",
    CREATED_AT: "Created At: ",
    TITLE: "Title: ",
    ID: "ID: ",
  },
};
