import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function AddPost({
  handleModalClose,
  showModal,
  onSavePostData,
  onInputChange,
}) {
  return (
    <Modal show={showModal} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Title</Form.Label>
            <Form.Control
              placeholder="Enter post title"
              id="title"
              onChange={onInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              id="description"
              placeholder="Add description for you blog"
              onChange={onInputChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onSavePostData}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
